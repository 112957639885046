import {
    nbaRecentlyViewedImpression,
    oneClickButtonClick,
    ownerCall,
    ownerChangeLocationClick,
    ownerChat,
    ownerEmailConfirmationNextStepsClick,
    ownerEmailSubcribeClick,
    ownerGetQuickEstimateClick,
    ownerPhoneNumber,
    ownerServiceInquiry,
    ownerText,
    ownerWebsite,
    recentlyViewedVehicleNBAClick,
} from 'reaxl-analytics-handlers';

import {
    srpActiveInteractionDuck,
    srpFiltersDuck,
} from '@/ducks/srp';
import {
    vdpResultsDuck,
} from '@/ducks/vdp';

export default {

    oneClickButtonClick: (metadata = {}, option) => {
        const { data, state } = metadata;
        const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
        return oneClickButtonClick({
            ...metadata,
            data: {
                ...data,
                clickType: data?.clickType || srpActiveInteractionDuck.selectors.getClickType(state),
            },
            filterValues,
        }, option);
    },
    ownerWebsite,
    ownerText,
    ownerChat,

    ownerCall: (metadata, option) => ownerCall({
        ...metadata,
        filterDuck: srpFiltersDuck,
    }, option),

    ownerPhoneNumber: (metadata, option) => ownerPhoneNumber({
        ...metadata,
        filterDuck: srpFiltersDuck,
    }, option),

    ownerEmailConfirmationNextStepsClick,

    ownerServiceInquiry: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return ownerServiceInquiry({
            ...metadata,
            data: {
                inventoryId: activeInventory?.id,
                ...data,
            },
        }, option);
    },

    ownerChangeLocationClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return ownerChangeLocationClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory?.id,
            },
        }, option);
    },

    ownerGetQuickEstimateClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return ownerGetQuickEstimateClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory?.id,
            },
        }, option);
    },

    ownerEmailSubcribeClick: (metadata, option) => {
        const { data, state } = metadata;
        const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
        return ownerEmailSubcribeClick({
            ...metadata,
            data: {
                ...data,
                inventoryId: activeInventory?.id,
            },
        }, option);
    },

    nbaRecentlyViewedImpression: (metadata, option) => {
        const { state } = metadata;
        const filterValues = srpFiltersDuck.selectors.getRequestValues(state);

        return nbaRecentlyViewedImpression({
            ...metadata,
            filterValues,
        }, option);
    },

    recentlyViewedVehicleNBAClick: (metadata, option) => {
        const { state } = metadata;
        const filterValues = srpFiltersDuck.selectors.getRequestValues(state);

        return recentlyViewedVehicleNBAClick({
            ...metadata,
            filterValues,
        }, option);
    },
};
