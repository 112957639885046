const getSpotlightUrgencyDriver = (urgencyDriverType) => {
    const spotlightUrgencyDrivers = {
        incentive: 'incentives',
        reduced: 'price-reduced',
        newlylisted: 'newly-listed',
        numviewed: 'number-viewed',
        custom: 'dealer-incentive',
        warranty: 'warranty',
        return: 'return-policy',
        default: 'default',
    };
    return spotlightUrgencyDrivers[urgencyDriverType];
};

export default getSpotlightUrgencyDriver;
