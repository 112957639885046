import { getWalletConfiguration } from '@atc/bonnet-reference';

import paymentsDuck from '@/ducks/paymentsDuck';

export default function withWalletConfigs() {
    return async (ctx) => {
        const [enableMyWallet] = ctx.useFeature('my_wallet');

        if (typeof window === 'undefined' && enableMyWallet) {
            try {
                const myWalletConfigs = await getWalletConfiguration();

                ctx.store.dispatch(paymentsDuck?.creators?.updateMyWallet({
                    configs: myWalletConfigs,
                }));

            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching wallet configuration: ', error);
            }
        }
    };
}
