import {
    compareAddListingClick as reaxlCompareAddListingClick,
    compareListingsDrawerClick as reaxlCompareListingsDrawerClick,
    inventoryCertifiedTileClick as reaxlInventoryCertifiedTileClick,
    inventoryClick as reaxlInventoryClick,
    inventoryMediaViewerClick as reaxlInventoryMediaViewerClick,
    inventoryProductClick as reaxlInventoryProductClick,
    inventoryProductImpressions as reaxlInventoryProductImpressions,
    inventoryVehicleConditionHighlightsClick as reaxlInventoryVehicleConditionHighlightsClick,
    inventoryVideoCompleted as reaxlInventoryVideoCompleted,
    inventoryVideoMilestones as reaxlInventoryVideoMilestones,
    inventoryVideoPlay as reaxlInventoryVideoPlay,
    mediaGalleryNdsCTAClick as reaxlMediaGalleryNdsCTAClick,
    mediaTabClick as reaxlMediaTabClick,
    mediaViewerPagination as reaxlMediaViewerPagination,
    shareClick as reaxlShareClick,
    vi360Click as reaxlVi360Click,
    viewAllMediaClick as reaxlViewAllMediaClick,
} from 'reaxl-analytics-handlers';

import { srpFiltersDuck } from '@/ducks/srp';
import { vdpResultsDuck } from '@/ducks/vdp';

export const inventoryCertifiedTileClick = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);

    return reaxlInventoryCertifiedTileClick({
        ...metadata,
        data: {
            ...data,
            inventoryId: activeInventory.id,
            eventSource: 'vehicle',
        },
    }, option);
};

export const inventoryClick = (metadata, option) => {
    const { data, state } = metadata;
    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);

    return reaxlInventoryClick({
        ...metadata,
        data: {
            ...data,
            eventSource: 'vehicle',
            pixallData: {
                ...data.pixallData,
                filterValues,
            },
        },
    }, option);
};

export const inventoryProductClick = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlInventoryProductClick({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const compareListingsDrawerClick = (metadata, option) => {
    const { data, state } = metadata;
    const { pixallData } = data;
    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);

    return reaxlCompareListingsDrawerClick({
        ...metadata,
        pixallData: {
            ...pixallData,
            filterValues,
        },
    }, option);
};

export const compareAddListingClick = (metadata, option) => {
    const { data, state } = metadata;
    const { pixallData } = data;
    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);

    return reaxlCompareAddListingClick({
        ...metadata,
        pixallData: {
            ...pixallData,
            filterValues,
        },
    }, option);
};

export const inventoryProductImpressions = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlInventoryProductImpressions({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const inventoryMediaViewerClick = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlInventoryMediaViewerClick({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const viewAllMediaClick = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlViewAllMediaClick({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const mediaViewerPagination = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlMediaViewerPagination({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const mediaTabClick = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlMediaTabClick({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const inventoryVehicleConditionHighlightsClick = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
    return reaxlInventoryVehicleConditionHighlightsClick({
        ...metadata,
        data: {
            filterValues,
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const inventoryVideoPlay = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlInventoryVideoPlay({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const inventoryVideoCompleted = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlInventoryVideoCompleted({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const inventoryVideoMilestones = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlInventoryVideoMilestones({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const mediaGalleryNdsCTAClick = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlMediaGalleryNdsCTAClick({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const shareClick = (metadata, option) => {
    // TODO on SRP GA is missing CD's 100 - 160
    const { data } = metadata;
    return reaxlShareClick({
        ...metadata,
        data: {
            ...data,
            inventoryId: data?.id,
        },
    }, option);
};

export const vi360Click = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlVi360Click({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};
