import { Module } from '@bonnet/modules';

import { authDuck } from '@/ducks';

const signedInCookieName = 'MyConsumerSignedIn';

function atcAuthModuleCreator() {
    return new Module({
        duck: authDuck,
        ctxMiddleware: ({ duck }) => (ctx) => {
            if (ctx.req) {
                if (ctx.req.cookies && ctx.req.cookies[signedInCookieName]) {
                    ctx.store.dispatch(duck.creators.setLoggedIn());
                    ctx.data.isLoggedIn = true;
                } else {
                    ctx.store.dispatch(duck.creators.setLoggedOut());
                    ctx.data.isLoggedIn = false;
                }
            }
        },
    });
}

const atcAuthModule = /* #__PURE__*/ (() => atcAuthModuleCreator())();

export default atcAuthModule;
